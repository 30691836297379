@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #000000;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-col{
  font-family: "Poppins", sans-serif !important;
  font-size: unset !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  background: #333946;
}

:where(.css-dev-only-do-not-override-17sses9)[class^="ant-layout"], :where(.css-dev-only-do-not-override-17sses9)[class*=" ant-layout"] {
    font-family: "Poppins", sans-serif !important;
    font-size: unset !important;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-row{
  font-size: unset !important;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px !important;
  }
  .btn-connect {
    width: auto !important;
    padding: 9px 10px !important;
  }
  
}


@font-face {
    font-family: ClashGroteskMedium;
    src: url(../../public/fonts/ClashGrotesk/ClashGrotesk-Medium.ttf);
}
@font-face {
    font-family: ClashGroteskRegular;
    src: url(../../public/fonts/ClashGrotesk/ClashGrotesk-Regular.ttf);
}
@font-face {
    font-family: ClashGroteskBold;
    src: url(../../public/fonts/ClashGrotesk/ClashGrotesk-Bold.ttf);
}
@font-face {
    font-family: ClashGroteskSemibold;
    src: url(../../public/fonts/ClashGrotesk/ClashGrotesk-Semibold.ttf);
}
@mixin sp() {
  @media screen and (max-width: 768px) {
    @content;
  }
}

.container {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.ant-menu-light .ant-menu-item-selected{
  background-color: transparent;
  color: #0c758d;
}
.ant-menu-light .ant-menu-item{
  height: auto !important;
  line-height: auto !important;

  @include sp{
    padding-left: 0px !important;
  }
}
@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1280px;
  }
}

@media (min-width: 1500px) {
  .container {
    width: 1368px;
  }
}

@mixin sp() {
  @media screen and (max-width: 767px) {
    @content;
  }
}
.btn-connect {
  border-radius: 12px;
  background: linear-gradient(261.77deg, #55E1A6 -4.72%, #05ABC1 107.55%);
  padding: 9px 20px;
  border: none !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  cursor: pointer;
  width: 172.08px;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0px 0px 0px 1.5px rgba(88, 243, 205, 1) inset;

  img {
    width: 20px;
    margin-right: 5px;
  }
}

.head-title{
  margin-top: 30px;
  margin-bottom: 30px !important;
  font-size: 44px;
  font-weight: 700;
  line-height: 64.8px;
  text-align: center;
  color: rgba(202, 236, 241, 1);
}

.ant-divider::after{
  border-color: #fff !important;
}
.ant-divider::before{
  border-color: #fff !important;
}

.header {
  top: 0;
    left: 0;
    border-bottom: 1px solid rgba(202, 236, 241, 0.07);
    background: rgb(4 148 166 / 44%);
    padding: 10px 30px;
    position: fixed;
    width: -webkit-fill-available;
    z-index: 99;

  .main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      .box-img {
        img {
          width: 185px;
        }
      }
    }

    .header-center {
      // max-width: 540px;
      // width: 100%;
      display: flex;
      justify-content: center;
      padding-left: 30px;
      padding-right: 30px;
      min-width: 700px;

      .header-menu {
        background: transparent !important;
        min-width: 100%;
        justify-content: center;
        border-bottom: none !important;

        .ant-menu-item {

          .ant-menu-title-content {
            color: #fff;
            font-size: 15px;
            font-weight: 500;
          }
        }

        .ant-menu-item-selected {

          .ant-menu-title-content {
            color: #FCFCFC;
          }
        }

        .ant-menu-item-selected::after {
          border-bottom: none !important;
        }
      }

      .search-header {
        width: 100%;
        max-width: 300px;

        .ant-input-affix-wrapper {
          border-radius: 12px !important;
          border: 1px solid var(--BG-White, rgba(255, 255, 255, 0.05)) !important;
          background-color: var(--BG-White, rgba(255, 255, 255, 0.05)) !important;

          .ant-input-prefix {
            font-size: 20px;
            color: #6F767E;
          }

          .ant-input {
            background-color: transparent !important;
            color: #fff !important;
            padding-left: 10px;

            &::placeholder {
              color: #6F767E;
            }
          }
        }
      }
    }

    .header-right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .round-connect {
        margin-left: 20px;
      }
    }
  }
}

.btn-gray{
  padding: 10px 20px;
  border: 1px solid rgba(166, 216, 221, 0.08);
  background: rgba(166, 216, 221, 0.12);
  border-radius: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: rgba(247, 247, 248, 1);
  cursor: pointer;
}
.link-a{
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: rgba(247, 247, 248, 1) !important;
  text-decoration: auto;

  &:hover{
    text-decoration: underline !important;
  }
}
.font-18{
  font-size: 18px !important;
}
.font-19{
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-24 {
  font-size: 24px !important;
}
.font-40 {
  font-size: 40px !important;
}
.text-gray-light{
  color: rgba(229, 231, 235, 0.6) !important;
}
.statistic-container{
  background: rgba(202, 236, 241, 0.07);
  padding: 15px;
  border-radius: 15px;
}
.text-main{
  color: rgba(247, 247, 248, 1);
}
.mt-15 {
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-5-mb{
  margin-top: 5px;
  @include sp{
    margin-top: 15px;
  }
}
.text-underline{
  text-decoration: underline;
}
.btn-lightblue{
  background: linear-gradient(90deg, #12C781 -441.83%, #63E9DA -98.47%, #09A9CB 244.9%);
  border-radius: 40px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 15px;
  cursor: pointer;

  &:disabled{
    opacity: 0.5;
    cursor: no-drop;
  }
  &:hover{
    background: linear-gradient(90deg, #12C781 -441.83%, #44c1b2 -98.47%, #0c758d 244.9%);
  }
}
.app-page {
  background-image: url(../../public/images/trailer.gif);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  

  @include sp {
    padding-top: 0px;
    background-size: cover;
  }

  .ant-content-cus{
    background: rgba(255, 255, 255, 1);
    border-top-left-radius: 20px;
    @include sp {
      border-radius: 0px;
    }
  }

  .app-hearder {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    background: transparent;
    // border-bottom: 1px solid var(--Card-Stroke, rgba(255, 255, 255, 0.10));
    // background: var(--Card-BG, rgba(26, 29, 31, 0.40));
    // box-shadow: 0px 32px 48px -8px rgba(0, 0, 0, 0.10), 0px 0px 14px -4px rgba(0, 0, 0, 0.05);
    // backdrop-filter: blur(16px);

    
  }

}

.logo-game{
  text-align: center;
}

.game-description{
  text-align: center;
  color: rgba(10, 234, 240, 1);
  font-family: ClashGroteskSemiBold !important;
  font-size: 84px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-top: 20px;
  text-shadow:  rgb(10, 234, 240) 5px 0 30px;
}
.sub-desc{
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  font-weight: 400;
  line-height: 37.5px;
  text-align: center;
  margin-top: 15px;
}
.game-info{
  background: radial-gradient(73.76% 388.18% at 42.36% 31.2%, #000D1F 0%, #00456B 100%);
  border-radius: 10px;
  border: 2px solid #000;
  padding: 30px;
  // max-width: 300px;
  margin: auto;
  margin-top: 30px;

  .item-info{
    text-align: center;

    @include sp{
      margin-bottom: 20px;
    }
  }
}
.bg-black{
  background: #000;
  padding: 4px;
  width: fit-content;
  margin: auto;
  
  img{
    width: 25px;
  }
}
.mb-10{
  margin-bottom: 10px;
}
.flex-center{
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}
.text-white{
  color: #fff;
}
.fsize-18{
  font-size: 18px;
}
.fsize-20{
  font-size: 20px;
}
.flex-left{
  display: flex;
  gap: 7px;
  align-items: center;
  justify-content: left;
}
.img-bt{
  text-align: center;
  width: 100%;
  img{
    max-width: 100%;
    width: auto;
  }
}
.bg-darkblue{
  background: linear-gradient(162.13deg, #002940e0 64.32%, #01163282 101.06%);
  padding-top: 70px;
}
.ant-menu-item-selected {

  .ant-menu-title-content{
    color: rgb(255 255 255) !important;
    text-decoration: underline;

    @include sp{
      color: rgb(76, 81, 242) !important;
    }
  }
}

:where(.css-dev-only-do-not-override-98ntnt).ant-menu-light.ant-menu-horizontal >.ant-menu-item::after{
  border-bottom: none !important;
}

.d-desktop{
  display: block;
  @include sp{
      display: none !important;
    }

}
.d-mobile{
  display: none;
  @include sp{
      display: block !important;
    }

}
